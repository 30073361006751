<template>
  <ui-modal
    class="ui-modal-images"
    width="600"
    :title="mediaType.name"
    :dialog="true"
    :saving="saving"
    @close="$emit('close')"
    @save="save"
  >
    <template v-slot:body>
      <div class="ui-modal-images__content">
        <v-file-input class="tw-hidden" :accept="mediaType.extension" v-model="modelData.file" ref="fileInput" />
        <v-avatar
          class="tw-overflow-y-auto tw-cursor-pointer hover:tw-bg-gray-200 tw-transition-colors"
          @click="openFile"
          v-cloak
          @drop.prevent="addDropFile"
          @dragover.prevent
          width="100%"
          color="#f3f3f3"
          height="210"
          size="auto"
          tile
        >
          <v-btn
            v-if="haveMedia"
            @click.stop="removeMedia"
            class="tw-text-xs tw-z-10"
            color="error"
            x-small
            absolute
            right
            top
          >
            <v-icon left small>
              {{ icons.mdiDelete }}
            </v-icon>
            {{ $t('button.remove') }}
          </v-btn>
          <v-img contain max-height="210" v-if="previewFile" :src="previewFile" />
          <v-icon v-else size="48">{{ icons.mdiDownload }}</v-icon>
        </v-avatar>

        <v-alert v-if="fileRulesErrors[0]" tile dense text color="error" class="tw-m-0 tw-text-xs">
          {{ fileRulesErrors[0] }}
        </v-alert>

        <div class="ui-modal-images__content__rules">
          <v-chip label color="#449afd" dark class="tw-p-4">
            <v-icon left>{{ icons.mdiRulerSquare }}</v-icon>
            <span class="tw-font-light tw-text-base">{{ $t('brands.dashboard.images.modal.settings') }}</span>
          </v-chip>
          <div class="tw-my-4 tw-flex tw-flex-row tw-justify-start tw-flex-wrap tw-gap-4">
            <v-tooltip nudge-left="-5" left>
              <template v-slot:activator="{ on }">
                <v-chip v-on="on">
                  <v-icon class="mytets">{{ icons.mdiArrowUpDown }}</v-icon>
                  <span class="tw-ml-2">
                    {{ $t('brands.dashboard.images.modal.px', { min: mediaType.minHeight, min: mediaType.maxHeight }) }}
                  </span>
                </v-chip>
              </template>
              {{ $t('brands.dashboard.images.modal.height') }}
            </v-tooltip>
            <v-tooltip nudge-left="-5" left>
              <template v-slot:activator="{ on }">
                <v-chip v-on="on">
                  <v-icon>{{ icons.mdiArrowLeftRight }}</v-icon>
                  <span class="tw-ml-2">
                    {{ $t('brands.dashboard.images.modal.px', { min: mediaType.minWidth, min: mediaType.maxWidth }) }}
                  </span>
                </v-chip>
              </template>
              {{ $t('brands.dashboard.images.modal.width') }}
            </v-tooltip>
            <v-tooltip nudge-left="-5" left>
              <template v-slot:activator="{ on }">
                <v-chip v-on="on">
                  <v-icon>{{ icons.mdiAspectRatio }}</v-icon>
                  <span class="tw-ml-2">
                    {{ $t('brands.dashboard.images.modal.px', { min: mediaType.minRatio, min: mediaType.maxRatio }) }}
                  </span>
                </v-chip>
              </template>
              {{ $t('brands.dashboard.images.modal.ratio') }}
            </v-tooltip>
            <v-tooltip nudge-left="-5" left>
              <template v-slot:activator="{ on }">
                <v-chip v-on="on">
                  <v-icon>{{ icons.mdiPuzzle }}</v-icon>
                  <span class="tw-ml-2">.{{ mediaType.extension }}</span>
                </v-chip>
              </template>
              {{ $t('brands.dashboard.images.modal.extension') }}
            </v-tooltip>
            <v-tooltip nudge-left="-5" left>
              <template v-slot:activator="{ on }">
                <v-chip v-on="on">
                  <v-icon>{{ icons.mdiPuzzle }}</v-icon>
                  <span class="tw-ml-2">{{ mediaType.key }}</span>
                </v-chip>
              </template>
              {{ $t('brands.dashboard.images.modal.key') }}
            </v-tooltip>
          </div>
        </div>
      </div>
    </template>
  </ui-modal>
</template>

<script>
import { validationMixin } from 'vuelidate'
import {
  mdiTrashCanOutline,
  mdiDownload,
  mdiRulerSquare,
  mdiPuzzle,
  mdiAspectRatio,
  mdiArrowUpDown,
  mdiArrowLeftRight,
  mdiDelete,
} from '@mdi/js'

import UiModal from '@/components/UI/Modal.vue'

const initDefaultData = () => ({
  file: null,
})

export default {
  name: 'UIModalMedia',
  mixins: [validationMixin],
  components: {
    UiModal,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    saving: {
      type: Boolean,
      required: true,
    },
    mediasClient: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        mdiTrashCanOutline,
        mdiDownload,
        mdiRulerSquare,
        mdiPuzzle,
        mdiAspectRatio,
        mdiArrowUpDown,
        mdiArrowLeftRight,
        mdiDelete,
      },
      modelData: {
        ...initDefaultData(),
      },
      originalMediaPath: null,
    }
  },
  created() {
    if (this.mediaAttachedToClient) {
      this.originalMediaPath = this.mediaAttachedToClient.path
    }
  },
  computed: {
    previewFile() {
      return this.modelData.file ? URL.createObjectURL(this.modelData.file) : this.originalMediaPath
    },
    mediaType() {
      return this.config.data.media
    },
    haveMedia() {
      return this.modelData.file || this.originalMediaPath
    },
    mediaAttachedToClient() {
      return this.mediasClient.find(media => media.type === this.mediaType.identifier)
    },
    fileRulesErrors() {
      const errors = []
      if (!this.$v.modelData.file.$dirty) return errors
      !this.$v.modelData.file.extension &&
        errors.push(this.$t('brands.dashboard.images.modal.error.extension', { extension: this.mediaType.extension }))
      return errors
    },
    nameFile() {
      return this.modelData.file?.name.split('.').pop()
    },
    mediaOrigin() {
      return this.config.data.origin
    },
  },
  methods: {
    addDropFile(e) {
      this.modelData.file = e.dataTransfer.files[0]
    },
    removeMedia() {
      this.originalMediaPath = null
      this.modelData.file = null
    },
    openFile() {
      this.$refs.fileInput.$refs.input.click()
    },
    extensionIsValid() {
      return !this.modelData.file || this.nameFile.toLowerCase() === this.mediaType.extension ? true : false
    },
    save() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.mediaAttachedToClient) {
          if (this.modelData.file) {
            this.$emit('save', {
              uuid: this.mediaAttachedToClient.uuid,
              file: this.modelData.file,
              origin: this.mediaOrigin,
              type: this.mediaType.identifier,
              create: false,
            })
          } else if (!this.originalMediaPath) {
            this.$emit('delete', { uuid: this.mediaAttachedToClient.uuid })
          } else {
            this.$emit('close')
          }
        } else if (this.haveMedia) {
          this.$emit('save', {
            file: this.modelData.file,
            type: this.mediaType.identifier,
            origin: this.mediaOrigin,
            create: true,
          })
        }
      }
    },
  },
  validations() {
    return {
      modelData: {
        file: {
          extension: () => this.extensionIsValid(),
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.ui-modal-images {
  &__content {
    &__rules {
      @apply tw-my-0 tw-p-4;
    }
  }
}
</style>
