<template>
  <div class="clients-dashboard-features" :class="{ 'clients-dashboard-features--dark': $vuetify.theme.dark }">
    <clients-dashboard-block
      :icon-header="icons.mdiHammerWrench"
      :title="$t('clients.dashboard.features.title')"
      :action="{
        display: true,
        label: $t('clients.dashboard.features.button'),
        mode: 'btn',
      }"
      @onAction="goToFeatures"
    >
      <template v-slot:body>
        <div class="tw-flex tw-flex-col tw-gap-4">
          <v-treeview
            class="client-features__treeview"
            selectable
            :items="features"
            item-children="childFeatures"
            item-key="identifier"
            v-model="modelFeatures"
          >
            <template v-slot:append="{ selected }">
              <v-switch disabled :label="$t(`switch.${selected}`)" :input-value="selected" />
            </template>
            <template v-slot:label="{ item }">
              {{ item.name.toUpperCase() }}
            </template>
          </v-treeview>
          <v-alert class="tw-text-center" v-if="features.length === 0">{{ $t('notification.error.noData') }}</v-alert>
        </div>
      </template>
    </clients-dashboard-block>
  </div>
</template>

<script>
import { mdiHammerWrench } from '@mdi/js'
import ClientsDashboardBlock from '@/components/Clients/Dashboard/Block.vue'

export default {
  name: 'ClientsDashboardFeatures',
  components: {
    ClientsDashboardBlock,
  },
  props: {
    features: {
      type: Array,
      required: true,
    },
    client: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modelFeatures: [],
      icons: { mdiHammerWrench },
    }
  },
  created() {
    this.modelFeatures = this.client.features
  },
  methods: {
    goToFeatures() {
      this.$router.push({ name: 'ClientFeatures', params: { id: this.client.uuid } })
    },
    featureIsActive(identifier) {
      return this.client.features.includes(identifier)
    },
  },
}
</script>

<style lang="scss">
.clients-dashboard-features {
  .v-treeview-node__toggle,
  .v-treeview-node__level {
    display: none !important;
  }
}
</style>

<style scoped lang="scss">
.clients-dashboard-features {
  @apply tw-rounded-xl;
  background-color: $white;

  &--dark {
    background-color: $vuetify-dark-1;
  }
}
</style>
