<template>
  <div class="clients-dashboard-legacy" :class="{ 'clients-dashboard-legacy--dark': $vuetify.theme.dark }">
    <clients-dashboard-block :icon-header="icons.mdiBriefcaseClock" :title="$t('clients.dashboard.legacy.title')">
      <template v-slot:body>
        <ui-block id="pcId" :label="$t('clients.dashboard.legacy.link.pc')">
          <template v-slot:body>
            <div class="tw-flex tw-gap-2">
              <v-text-field
                :value="client.brandId"
                id="pcId"
                readonly
                solo
                flat
                outlined
                dense
                disabled
                :placeholder="$t('clients.dashboard.legacy.link.pc')"
              />
              <v-btn
                target="_blank"
                :href="`${URLS.BO_V1}/bo/main/brand_edit.php?elt_id=${client.brandId}`"
                text
                color="#449afd"
              >
                {{ $t('clients.dashboard.legacy.button') }}
              </v-btn>
            </div>
          </template>
        </ui-block>
        <ui-block id="lpeId" :label="$t('clients.dashboard.legacy.link.lpe')">
          <template v-slot:body>
            <div class="tw-flex tw-gap-2">
              <v-text-field
                :value="client.clientId"
                id="lpeId"
                readonly
                solo
                flat
                outlined
                disabled
                dense
                :placeholder="$t('clients.dashboard.legacy.link.lpe')"
              />
              <v-btn :to="{ name: 'Client', params: { id: client.clientId } }" text color="#449afd">
                {{ $t('clients.dashboard.legacy.button') }}
              </v-btn>
            </div>
          </template>
        </ui-block>
        <ui-block id="pcSa" :label="$t('clients.dashboard.legacy.link.pcSa')">
          <template v-slot:body>
            <div class="tw-flex tw-gap-2">
              <v-text-field
                :value="client.clientId"
                id="pcSa"
                readonly
                solo
                flat
                outlined
                disabled
                dense
                :placeholder="$t('clients.dashboard.legacy.link.pcSa')"
              />
              <v-btn
                :to="{ name: 'Brand', params: { id: client.brandId, currentTab: 'reviews' } }"
                text
                color="#449afd"
              >
                {{ $t('clients.dashboard.legacy.button') }}
              </v-btn>
            </div>
          </template>
        </ui-block>
        <ui-block id="radar" :label="$t('clients.dashboard.legacy.link.radar')">
          <template v-slot:body>
            <div class="tw-flex tw-gap-2">
              <v-text-field
                :value="client.clientId"
                id="radarId"
                readonly
                solo
                flat
                outlined
                disabled
                dense
                :placeholder="$t('clients.dashboard.legacy.link.radar')"
              />
              <v-btn
                target="_blank"
                :href="`${URLS.RADAR}/${client.radarUuid}?competitor=1&locale=${currentUser.locale}`"
                text
                color="#449afd"
              >
                {{ $t('clients.dashboard.legacy.button') }}
              </v-btn>
            </div>
          </template>
        </ui-block>
      </template>
    </clients-dashboard-block>
  </div>
</template>

<script>
import { mdiBriefcaseClock } from '@mdi/js'
import ClientsDashboardBlock from '@/components/Clients/Dashboard/Block.vue'
import UiBlock from '@/components/UI/Block.vue'
import { URLS } from '@/config/urls.config'

export default {
  name: 'ClientsDashboardLegacy',
  components: {
    ClientsDashboardBlock,
    UiBlock,
  },
  props: {
    client: {
      type: Object,
      required: true,
      default: () => {},
    },
    currentUser: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      URLS,
      icons: {
        mdiBriefcaseClock,
      },
    }
  },
}
</script>

<style scoped lang="scss">
.clients-dashboard-legacy {
  @apply tw-rounded-xl;
  background-color: $white;

  &--dark {
    background-color: $vuetify-dark-1;
  }
}
</style>
