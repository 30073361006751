<template>
  <v-fade-transition group leave-absolute>
    <v-overlay key="overlay" :value="updating" v-if="updating" absolute>
      <v-progress-circular indeterminate size="64" width="6" />
    </v-overlay>
    <form
      key="clients-dashboard"
      class="clients-dashboard"
      @submit.prevent="onSubmit"
      novalidate
      autocomplete="off"
      v-else
    >
      <div class="sm:tw-grid sm:tw-grid-cols-3 tw-gap-6">
        <clients-dashboard-information class="tw-col-span-2" :informations="clientInformations" ref="information" />
        <clients-dashboard-features :client="currentClient" :features="features" ref="features" />
      </div>
      <div class="sm:tw-grid sm:tw-grid-cols-3 tw-gap-6">
        <clients-dashboard-active-contract
          :client="currentClient"
          :active-contracts="activeContracts"
          ref="activeContract"
          class="tw-col-span-2"
        />
        <clients-dashboard-legacy
          class="clients-dashboard__col"
          :client="currentClient"
          :current-user="currentUser"
          ref="legacy"
        />
      </div>
      <div class="sm:tw-grid sm:tw-grid-cols-3 tw-gap-6">
        <clients-dashboard-brand class="tw-col-span-1" :client="currentClient" :markets="brands" ref="markets" />
        <brands-dashboard-images
          class="clients-dashboard__images"
          ref="images"
          :medias-types="mediasTypeAvailable"
          :medias-client="currentMedias"
          @open="media => openModalMedia({ media, origin: currentClient.identifier })"
        />
      </div>
      <v-btn
        bottom
        right
        fixed
        class="tw-mx-auto"
        type="submit"
        color="#449afd"
        dark
        :loading="saving"
        :disabled="saving"
      >
        {{ $t('button.save') }}
      </v-btn>
    </form>
    <ui-modal-media
      key="media"
      v-if="openModal === true"
      :config="configModal"
      :medias-client="currentMedias"
      :saving="savingMedia"
      @save="saveMedia"
      @close="openModal = false"
      @delete="deleteMedia"
    />
  </v-fade-transition>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import MediaModalMixin from '@/mixins/mediaModal.mixin'

import ClientsDashboardInformation from '@/components/Clients/Dashboard/Information.vue'
import ClientsDashboardBrand from '@/components/Clients/Dashboard/Brand.vue'
import ClientsDashboardActiveContract from '@/components/Clients/Dashboard/ActiveContract.vue'
import ClientsDashboardFeatures from '@/components/Clients/Dashboard/Features.vue'
import ClientsDashboardLegacy from '@/components/Clients/Dashboard/Legacy.vue'
import BrandsDashboardImages from '@/components/brands/Dashboard/Images.vue'
import UiModalMedia from '@/components/UI/Modal/Media.vue'

export default {
  name: 'ClientsDashboard',
  components: {
    ClientsDashboardInformation,
    ClientsDashboardBrand,
    ClientsDashboardActiveContract,
    ClientsDashboardFeatures,
    ClientsDashboardLegacy,
    BrandsDashboardImages,
    UiModalMedia,
  },
  mixins: [MediaModalMixin],
  async created() {
    this.setUpdating(true)
    await Promise.all([
      this.getClientCore({ uuid: this.$route.params.id }),
      this.getVerticals(),
      this.getTypes(),
      this.getFeatures(),
    ])

    await Promise.all([
      this.getMedias({
        query: [
          { key: 'origin', value: this.currentClient.identifier },
          { key: 'status', value: true },
        ],
      }),
      this.getMediasType({
        query: [
          { key: 'allowedEntities.name', value: 'client' },
          { key: 'uploadable', value: 'true' },
          { key: 'status', value: 'true' },
        ],
      }),
      this.getBrands({ query: [{ key: 'client.uuid', value: this.currentClient.uuid }] }),
      this.getActiveContracts({
        query: [
          { key: 'client.uuid', value: this.currentClient.uuid },
          { key: 'status', value: true },
        ],
      }),
    ])
    this.setUpdating(false)
  },
  data() {
    return {
      saving: false,
    }
  },
  computed: {
    ...mapState({
      currentMedias: state => state.media.currentMedias,
      mediasTypeAvailable: state => state.media.mediasTypeAvailable,

      currentClient: state => state.client.currentClient,
      activeContracts: state => state.client.activeContracts,

      currentUser: state => state.backoffice.currentUser,
      updating: state => state.backoffice.updating,
      brands: state => state.brand.brands,
      features: state => state.setting.features,
    }),
    clientInformations() {
      const { uuid, name, legalName, defaultLocaleIsoCode, vertical, type, mediaPath, category, active } =
        this.currentClient
      return { uuid, name, legalName, defaultLocaleIsoCode, vertical, type, mediaPath, category, active }
    },
  },
  methods: {
    ...mapActions({
      getFeatures: 'setting/getFeatures',
      getTypes: 'setting/getTypes',
      getVerticals: 'setting/getVerticals',

      setUpdating: 'backoffice/setUpdating',
      setAlert: 'backoffice/setAlert',

      getClientCore: 'client/getClientCore',
      patchClient: 'client/patchClient',
      getActiveContracts: 'client/getActiveContracts',

      getBrands: 'brand/getBrands',

      getMediasType: 'media/getMediasType',
      getMedias: 'media/getMedias',
    }),
    async onSubmit() {
      const client = {
        ...this.$refs.information.modelInformation,
        features: this.$refs.features.modelFeatures,
      }
      this.saving = true
      this.$refs.information.$v.$touch()
      this.$refs.activeContract.$v.$touch()

      try {
        if (!this.$refs.information.$v.$invalid && !this.$refs.activeContract.$v.$invalid) {
          await this.patchClient({ uuid: this.currentClient.uuid, client })
          this.setAlert({
            color: 'success',
            text: this.$t('notification.update.client', { name: this.currentClient.name }),
          })
        }
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('notification.error.default'),
        })
      }
      this.saving = false
    },
  },
}
</script>

<style lang="scss" scoped>
.clients-dashboard {
  @apply sm:tw-p-8 tw-flex tw-flex-col tw-gap-4;

  &__col {
    height: fit-content;
  }

  &__images {
    width: inherit;
    height: fit-content;

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      width: fit-content;
    }
  }
}
</style>
