<template>
  <div class="clients-dashboard-brand" :class="{ 'clients-dashboard-brand--dark': $vuetify.theme.dark }">
    <clients-dashboard-block :icon-header="icons.mdiStoreCog" :title="$t('clients.dashboard.brand.title')">
      <template v-slot:body>
        <div>
          <v-list class="tw-text-center">
            <v-list-item
              :to="{ name: 'BrandsDashboard', params: { id: brand.uuid } }"
              class="tw-p-0"
              v-for="(brand, index) in markets"
              :key="`brand ${index}`"
            >
              <v-list-item-content class="tw-underline tw-text-left">
                <v-list-item-title class="tw-ml-4 tw-text-sm tw-font-semibold">{{ brand.name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon class="tw-my-1">
                <v-avatar tile class="tw-mr-4" color="#c5c5c5" size="44">
                  <v-img width="44px" :src="brand.mediaPath" />
                </v-avatar>
              </v-list-item-icon>
            </v-list-item>
            <v-btn :to="{ name: 'BrandsCreate', params: { id: client.uuid } }" class="tw-mt-6" outlined color="#449afd">
              <v-icon class="tw-mr-2">{{ icons.mdiPlus }}</v-icon>
              {{ $t('clients.dashboard.brand.create') }}
            </v-btn>
          </v-list>
        </div>
      </template>
    </clients-dashboard-block>
  </div>
</template>

<script>
//* Missing BRAND LOGO
import ClientsDashboardBlock from '@/components/Clients/Dashboard/Block.vue'
import { mdiPlus, mdiStoreCog } from '@mdi/js'

export default {
  name: 'ClientsDashboardBrand',
  props: {
    client: {
      type: Object,
      required: true,
    },
    markets: {
      type: Array,
      required: true,
    },
  },
  components: {
    ClientsDashboardBlock,
  },
  data() {
    return {
      icons: {
        mdiPlus,
        mdiStoreCog,
      },
    }
  },
}
</script>

<style scoped lang="scss">
.clients-dashboard-brand {
  @apply tw-rounded-xl;
  background-color: $white;

  &--dark {
    background-color: $vuetify-dark-1;
  }
}
</style>
