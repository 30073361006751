<template>
  <div
    class="clients-dashboard-active-contract"
    :class="{ 'clients-dashboard-active-contract--dark': $vuetify.theme.dark }"
  >
    <clients-dashboard-block
      :title="$t('clients.dashboard.activeContract.title')"
      :icon-header="icons.mdiFileDocumentEdit"
    >
      <template v-slot:body>
        <v-data-table
          class="tw-mb-4"
          height="100%"
          item-key="id"
          fixed-header
          dense
          hide-default-footer
          :loading="isUpdate"
          :headers="headers"
          :items="modelActiveContracts"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <clients-dashboard-modal-new-contract
              :client="client"
              :initial-data="currentContract"
              :is-edit="true"
              @add="editNewContract"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs" @click="editContract(item)" small class="mr-2">
                  {{ icons.mdiPencil }}
                </v-icon>
              </template>
            </clients-dashboard-modal-new-contract>

            <v-menu
              :bottom="true"
              offset-y
              nudge-left="50%"
              max-width="200px"
              :close-on-content-click="false"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs" color="error" small class="mr-2">
                  {{ icons.mdiDelete }}
                </v-icon>
              </template>
              <v-card>
                <v-card-text>
                  {{ $t('clients.dashboard.activeContract.delete') }}
                </v-card-text>
                <v-card-actions class="tw-pt-0">
                  <v-btn
                    @click="editNewContract({ id: item.id, status: false })"
                    class="tw-mx-auto tw-w-1/2"
                    color="error"
                    small
                    outlined
                    >{{ $t('button.delete') }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-menu>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-icon v-if="item.status === true" color="success">{{ icons.mdiCheckboxMarkedCircle }}</v-icon>
            <v-icon v-else color="error">{{ icons.mdiCloseCircle }}</v-icon>
          </template>
          <template v-slot:[`item.dateStart`]="{ item }">
            {{ formatedDate(item.dateStart, 'L') }}
          </template>
          <template v-slot:[`item.dateEnd`]="{ item }">
            {{ formatedDate(item.dateEnd, 'L') }}
          </template>
          <template v-slot:[`item.state`]="{ item }">
            <v-icon color="success" v-if="item.state === 'valid'">
              {{ icons.mdiCheckCircle }}
            </v-icon>
            <v-icon color="error" v-if="item.state === 'invalid'">
              {{ icons.mdiCloseCircle }}
            </v-icon>
            <v-icon color="#449afd" v-if="item.state === 'past'">
              {{ icons.mdiCalendarRemove }}
            </v-icon>
            <v-icon color="warning" v-if="item.state === 'future'">
              {{ icons.mdiCalendarClock }}
            </v-icon>
          </template>
        </v-data-table>
        <div class="tw-text-center">
          <clients-dashboard-modal-new-contract :client="client" @add="pushNewContract" />
        </div>
      </template>
    </clients-dashboard-block>
  </div>
</template>

<script>
import { formatedDate } from '@/utils/date.util'
import { mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import {
  mdiPencil,
  mdiSortCalendarDescending,
  mdiSortCalendarAscending,
  mdiCheckboxMarkedCircle,
  mdiCloseCircle,
  mdiCheckCircle,
  mdiCalendarClock,
  mdiFileDocumentEdit,
  mdiBriefcaseClock,
  mdiDelete,
  mdiCalendarRemove,
} from '@mdi/js'
import { clone } from '@/utils/utilities.util'

import ClientsDashboardBlock from '@/components/Clients/Dashboard/Block.vue'
import ClientsDashboardModalNewContract from '@/components/Clients/Dashboard/ModalNewContract.vue'

export default {
  name: 'ClientsDashboardActiveContract',
  components: {
    ClientsDashboardBlock,
    ClientsDashboardModalNewContract,
  },
  mixins: [validationMixin],
  props: {
    activeContracts: {
      type: Array,
      required: true,
    },
    client: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formatedDate,
      icons: {
        mdiSortCalendarDescending,
        mdiSortCalendarAscending,
        mdiCheckboxMarkedCircle,
        mdiCloseCircle,
        mdiPencil,
        mdiCheckCircle,
        mdiCalendarClock,
        mdiFileDocumentEdit,
        mdiBriefcaseClock,
        mdiDelete,
        mdiCalendarRemove,
      },
      modelActiveContracts: [],
      isUpdate: false,
      currentContract: null,
    }
  },
  watch: {
    activeContracts: {
      immediate: true,
      handler() {
        this.modelActiveContracts = clone(this.activeContracts)
      },
      deep: true,
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('clients.dashboard.headers.name'),
          value: 'name',
          sortable: true,
        },
        {
          text: this.$t('clients.dashboard.headers.dateStart'),
          value: 'dateStart',
          sortable: true,
        },
        {
          text: this.$t('clients.dashboard.headers.dateEnd'),
          value: 'dateEnd',
          sortable: true,
        },
        {
          text: this.$t('clients.dashboard.headers.status'),
          value: 'state',
          sortable: true,
        },
        {
          text: this.$t('clients.dashboard.headers.actions'),
          value: 'actions',
          sortable: false,
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      setAlert: 'backoffice/setAlert',
      postActiveContracts: 'client/postActiveContracts',
      patchActiveContracts: 'client/patchActiveContracts',
    }),
    editContract(item) {
      this.currentContract = item
    },
    activeContractsErrors(index, fieldName) {
      const errors = []
      if (!this.$v.modelActiveContracts.$each[index][fieldName].$dirty) return errors
      !this.$v.modelActiveContracts.$each[index][fieldName].required && errors.push(this.$t('error.required'))
      return errors
    },
    async pushNewContract(contract) {
      this.isUpdate = true
      try {
        await this.postActiveContracts({ contract })
      } catch (error) {
        if (error.response.status === 422) {
          this.setAlert({
            color: 'error',
            text: this.$t('notification.error.invalideDateRange'),
          })
        } else {
          this.setAlert({
            color: 'error',
            text: this.$t('notification.error.default'),
          })
        }
      }
      this.isUpdate = false
    },
    async editNewContract(contract) {
      this.isUpdate = true
      try {
        await this.patchActiveContracts({ params: { id: contract.id }, body: contract })
      } catch (error) {
        this.setAlert({
          color: 'error',
          text: this.$t('notification.error.default'),
        })
      }
      this.isUpdate = false
    },
  },
  validations() {
    return {
      modelActiveContracts: {
        $each: {
          name: {
            required,
          },
          dateStart: {
            required,
          },
          dateEnd: {
            required,
          },
        },
      },
    }
  },
}
</script>

<style scoped lang="scss">
.clients-dashboard-active-contract {
  @apply tw-rounded-xl;
  background-color: $white;

  &--dark {
    background-color: $vuetify-dark-1;
  }

  &__grid {
    @apply tw-grid sm:tw-grid-cols-2 tw-gap-x-6 tw-w-full tw-my-1;
  }
}
</style>
