<template>
  <div class="brands-dashboard-images" :class="{ 'brands-dashboard-images--dark': $vuetify.theme.dark }">
    <clients-dashboard-block :icon-header="icons.mdiFolderImage" :title="$t('brands.dashboard.images.title')">
      <template v-slot:body>
        <div class="brands-dashboard-images__grid">
          <div
            v-for="(media, index) in mediasTypes"
            :key="`media ${index}`"
            class="hover:tw-bg-gray-100 tw-transition-colors tw-p-4 tw-flex tw-flex-col tw-items-center tw-gap-2 tw-cursor-pointer"
            @click="$emit('open', media)"
          >
            <v-avatar color="#cbd5e1" size="115" tile>
              <v-img v-if="mediaClientAvailable(media)" :src="mediaClientAvailable(media).path" />
              <v-icon size="48" v-else>{{ icons.mdiDownload }}</v-icon>
            </v-avatar>
            <div class="tw-text-center">
              <span>{{ $t(`brands.dashboard.images.${media.key}`) }}</span>
              <div class="tw-text-xs">(.{{ media.extension }})</div>
            </div>
          </div>
        </div>
      </template>
    </clients-dashboard-block>
  </div>
</template>

<script>
import { mdiDownload, mdiFolderImage } from '@mdi/js'

import ClientsDashboardBlock from '@/components/Clients/Dashboard/Block.vue'

export default {
  name: 'BrandsDashboardImages',
  components: {
    ClientsDashboardBlock,
  },
  props: {
    mediasTypes: {
      type: Array,
      required: true,
    },
    mediasClient: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        mdiDownload,
        mdiFolderImage,
      },
      modelImages: {
        logo: null,
        favicon: null,
      },
    }
  },
  methods: {
    mediaClientAvailable(mediaType) {
      return this.mediasClient.find(media => media.type === mediaType.identifier)
    },
  },
}
</script>

<style scoped lang="scss">
.brands-dashboard-images {
  @apply tw-rounded-xl;
  background-color: $white;

  &--dark {
    background-color: $vuetify-dark-1;
  }

  &__grid {
    @apply tw-flex tw-flex-col tw-flex-wrap sm:tw-flex-row tw-justify-center tw-gap-8 tw-mt-4 tw-mx-4;
  }
}
</style>
