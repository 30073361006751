import { encodeImageFileAsURL } from '@/utils/utilities.util'
import { mapActions } from 'vuex'

const initDefaultModal = () => ({
  data: null,
})

const MediaModalMixin = {
  data() {
    return {
      configModal: {
        ...initDefaultModal(),
      },
      openModal: false,
      savingMedia: false,
    }
  },
  methods: {
    ...mapActions({
      postMedia: 'media/postMedia',
      patchMedia: 'media/patchMedia',
      putMediaApi: 'media/putMediaApi',
    }),
    openModalMedia(media) {
      this.configModal.data = media
      this.openModal = true
    },
    resetModalMedia() {
      this.configModal = initDefaultModal()
      this.openModal = false
    },
    async saveMedia(media) {
      this.savingMedia = true
      try {
        await encodeImageFileAsURL(media.file, async file => {
          const body = {
            origin: media.origin,
            type: media.type,
            originalFileName: media.file.name,
            file,
          }

          if (media.create === true) {
            await this.postMedia(body)
          } else {
            await this.putMediaApi({ params: { uuid: media.uuid }, body })
          }
          this.setAlert({
            color: 'success',
            text: this.$t(`notification.${media.create ? 'create' : 'update'}.media`, { name: media.file.name }),
          })
          this.resetModalMedia()
          this.savingMedia = false
        })
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('notification.error.default'),
        })
      }
    },
    async deleteMedia(media) {
      this.savingMedia = true
      try {
        await this.patchMedia({
          params: { uuid: media.uuid },
          body: {
            status: false,
          },
        })
        this.setAlert({
          color: 'success',
          text: this.$t('notification.delete.media'),
        })
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('notification.error.default'),
        })
      }
      this.resetModalMedia()
      this.savingMedia = false
    },
  },
}

export default MediaModalMixin
