<template>
  <div class="clients-dashboard-information" :class="{ 'clients-dashboard-information--dark': $vuetify.theme.dark }">
    <clients-dashboard-block
      :title="$t('clients.dashboard.block.title')"
      :subtitle="informations.name"
      :with-large-header="true"
      :action="{
        display: true,
        links: [
          {
            label: $t('clients.dashboard.information.goToClientList'),
            to: { name: 'ClientsList' },
          },
        ],
        mode: 'link',
      }"
    >
      <template v-slot:body>
        <div class="clients-dashboard-information__grid">
          <ui-block
            class="required-asterisk"
            id="nameLabel"
            :label="$t('clients.dashboard.information.form.name.label')"
          >
            <template v-slot:body>
              <v-text-field
                v-model="modelInformation.name"
                id="name"
                solo
                flat
                outlined
                dense
                :placeholder="$t('clients.dashboard.information.form.name.placeholder')"
                :error-messages="simpleErrors('modelInformation', 'name')"
              />
            </template>
          </ui-block>
          <ui-block
            class="required-asterisk"
            id="legalNameLabel"
            :label="$t('clients.dashboard.information.form.legalName.label')"
          >
            <template v-slot:body>
              <v-text-field
                v-model="modelInformation.legalName"
                id="legalName"
                solo
                flat
                outlined
                dense
                :placeholder="$t('clients.dashboard.information.form.legalName.placeholder')"
                :error-messages="simpleErrors('modelInformation', 'legalName')"
              />
            </template>
          </ui-block>
          <ui-block
            id="defaultLocaleIsoCodeLabel"
            :label="$t('clients.dashboard.information.form.defaultLocaleIsoCode.label')"
          >
            <template v-slot:body>
              <v-autocomplete
                v-model="modelInformation.defaultLocaleIsoCode"
                id="defaultLocaleIsoCode"
                solo
                flat
                outlined
                dense
                item-value="code"
                item-text="name"
                :items="locales"
                :placeholder="$t('clients.dashboard.information.form.defaultLocaleIsoCode.placeholder')"
              />
            </template>
          </ui-block>
          <ui-block
            class="required-asterisk"
            id="uuidLabel"
            :label="$t('clients.dashboard.information.form.uuid.label')"
          >
            <template v-slot:body>
              <v-text-field
                v-model="modelInformation.uuid"
                id="uuid"
                solo
                flat
                readonly
                dense
                :placeholder="$t('clients.dashboard.information.form.uuid.placeholder')"
                :error-messages="simpleErrors('modelInformation', 'uuid')"
              />
            </template>
          </ui-block>
          <ui-block id="verticalLabel" :label="$t('clients.dashboard.information.form.vertical.label')">
            <template v-slot:body>
              <v-autocomplete
                v-model="modelInformation.vertical"
                id="vertical"
                solo
                flat
                outlined
                dense
                item-value="identifier"
                item-text="name"
                :items="verticals"
                :placeholder="$t('clients.dashboard.information.form.vertical.placeholder')"
              />
            </template>
          </ui-block>
          <ui-block id="categoryLabel" :label="$t('clients.dashboard.information.form.category.label')">
            <template v-slot:body>
              <v-autocomplete
                v-model="modelInformation.category"
                id="category"
                solo
                flat
                outlined
                dense
                item-value="value"
                item-text="label"
                :items="categories"
                :placeholder="$t('clients.dashboard.information.form.category.placeholder')"
              />
            </template>
          </ui-block>
          <ui-block id="typeLabel" :label="$t('clients.dashboard.information.form.type.label')">
            <template v-slot:body>
              <v-autocomplete
                v-model="modelInformation.type"
                id="type"
                solo
                flat
                outlined
                dense
                item-value="identifier"
                item-text="name"
                :items="types"
                :placeholder="$t('clients.dashboard.information.form.type.placeholder')"
              />
            </template>
          </ui-block>

          <ui-block id="activeLabel" :label="$t('clients.dashboard.information.form.active.label')">
            <template v-slot:body>
              <v-switch id="active" class="tw-mt-0" v-model="modelInformation.active" />
            </template>
          </ui-block>
        </div>
        <ui-block
          id="mediaPathLabel"
          class="tw-cursor-pointer"
          :label="$t('clients.dashboard.information.form.mediaPath.label')"
        >
          <template v-slot:body>
            <v-text-field
              v-model="modelInformation.mediaPath"
              id="mediaPath"
              solo
              flat
              outlined
              dense
              readonly
              :placeholder="$t('clients.dashboard.information.form.mediaPath.placeholder')"
              :append-icon="icons.mdiContentCopy"
              @click:append="copyToClipboard(modelInformation.mediaPath)"
            />
          </template>
        </ui-block>
      </template>
    </clients-dashboard-block>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { URLS } from '@/config/urls.config'
import { validationMixin } from 'vuelidate'
import ErrorsMixin from '@/mixins/errors.mixin'
import { required } from 'vuelidate/lib/validators'
import { copyToClipboard } from '@/utils/utilities.util'
import { mdiContentCopy } from '@mdi/js'

import ClientsDashboardBlock from '@/components/Clients/Dashboard/Block.vue'
import UiBlock from '@/components/UI/Block.vue'

export default {
  name: 'ClientsDashboardInformation',
  components: {
    ClientsDashboardBlock,
    UiBlock,
  },
  mixins: [validationMixin, ErrorsMixin],
  props: {
    informations: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      URLS,
      icons: {
        mdiContentCopy,
      },
      modelInformation: {
        uuid: '',
        category: '',
        name: '',
        legalName: '',
        defaultLocaleIsoCode: null,
        vertical: null,
        type: null,
        mediaPath: '',
      },
    }
  },
  created() {
    this.modelInformation = {
      ...this.modelInformation,
      ...this.informations,
    }
  },
  computed: {
    ...mapState({
      types: state => state.setting.types,
      verticals: state => state.setting.verticals,
      features: state => state.setting.features,

      locales: state => state.backoffice.locales,
    }),
    categories() {
      return [
        {
          label: this.$t('clients.dashboard.information.form.category.options.client'),
          value: 'client',
        },
        {
          label: this.$t('clients.dashboard.information.form.category.options.prospect'),
          value: 'prospect',
        },
        {
          label: this.$t('clients.dashboard.information.form.category.options.internal'),
          value: 'internal',
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      setAlert: 'backoffice/setAlert',
    }),
    async copyToClipboard(string) {
      try {
        await copyToClipboard(string)
        this.setAlert({
          color: 'success',
          text: this.$t('notification.success.copy'),
        })
      } catch (error) {
        this.setAlert({
          color: 'error',
          text: this.$t('notification.error.default'),
        })
      }
    },
  },
  validations() {
    return {
      modelInformation: {
        uuid: {
          required,
        },
        name: {
          required,
        },
        legalName: {
          required,
        },
      },
    }
  },
}
</script>

<style scoped lang="scss">
.clients-dashboard-information {
  @apply tw-rounded-xl;
  background-color: $white;

  &--dark {
    background-color: $vuetify-dark-1;
  }

  &__grid {
    @apply tw-grid sm:tw-grid-cols-2 tw-gap-x-6 tw-w-full;
  }
}
</style>
