<template>
  <div class="clients-dashboard-block">
    <v-card elevation="0" class="tw-flex tw-mb-4">
      <v-list-item v-if="withLargeHeader" class="clients-dashboard-block__header" two-line>
        <v-avatar class="tw-mr-4 tw-my-auto" color="#449afd" size="70">
          <v-icon size="52" dark>{{ icons.mdiAccountOutline }}</v-icon>
        </v-avatar>
        <v-list-item-content class="tw-p-0">
          <v-list-item-title class="clients-dashboard-block__header__title">
            {{ title }}
          </v-list-item-title>
          <v-list-item-subtitle
            :class="{ 'clients-dashboard-block__header__subtitle--dark': this.$vuetify.theme.dark }"
            class="clients-dashboard-block__header__subtitle"
          >
            {{ subtitle }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-else
        class="clients-dashboard-block__header--small"
        :class="{ 'clients-dashboard-block__header--small--no-flex': action.mode === 'switch' }"
        two-line
      >
        <v-icon class="tw-mr-4" color="#449afd" size="26" dark>{{ iconHeader }}</v-icon>
        <v-list-item-content class="tw-p-0">
          <v-list-item-title class="clients-dashboard-block__header__title">
            {{ title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item-action class="tw-ml-auto tw-w-auto tw-self-center" v-if="action.display === true">
        <v-btn v-if="action.mode === 'btn'" small color="#449afd" @click="$emit('onAction')" text>
          {{ action.label }}
        </v-btn>
        <div v-if="action.mode === 'link'">
          <v-btn
            class="clients-dashboard-block__header__link"
            v-for="(link, index) in action.links"
            small
            color="#449afd"
            :href="link.href"
            exact
            :to="link.to"
            elevation="0"
            text
            :key="`link ${index}`"
          >
            <span class="tw-text-xs">{{ link.label }}</span>
          </v-btn>
        </div>
        <div class="tw-flex tw-flex-row" v-if="action.mode === 'switch'">
          <v-switch @change="status => $emit('onAction', status)" :input-value="action.value" />
          <span class="tw-ml-2">{{ $t('brands.dashboard.identity.switch') }}</span>
        </div>
      </v-list-item-action>
    </v-card>
    <slot name="body"></slot>
  </div>
</template>

<script>
import { mdiAccountOutline } from '@mdi/js'
export default {
  name: 'ClientsDashboardBlock',
  props: {
    withLargeHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
    btnLabel: {
      type: String,
      required: false,
      default: '',
    },
    iconHeader: {
      type: String,
      required: false,
      default: '',
    },
    action: {
      type: Object,
      required: false,
      default: () => ({
        display: false,
        mode: '',
        label: '',
      }),
    },
  },
  data() {
    return {
      icons: {
        mdiAccountOutline,
      },
    }
  },
}
</script>

<style scoped lang="scss">
.clients-dashboard-block {
  @apply tw-py-8 tw-px-4 tw-mx-4;

  &__header {
    @apply tw-px-0 tw-w-1/2;

    &--small {
      @apply tw-px-0;
      min-height: auto;

      &--no-flex {
        flex: 0;
      }
    }

    &__link {
      @apply tw-py-1 tw-mr-2 #{!important};
    }

    &__title {
      @apply tw-text-xl tw-font-semibold;
      color: #449afd;
    }

    &__subtitle {
      @apply tw-text-2xl tw-font-black;
      color: $black !important;

      &--dark {
        color: $white !important;
      }
    }
  }
}
</style>
