<template>
  <v-menu
    v-model="openModal"
    class="clients-dashboard-modal-new-contract"
    :bottom="true"
    offset-y
    nudge-left="50%"
    max-width="350px"
    :close-on-content-click="false"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs }">
        <v-btn v-on="on" v-bind="attrs" class="tw-mt-2" outlined color="#449afd">
          <v-icon class="tw-mr-2">{{ icons.mdiPlus }}</v-icon>
          {{ $t('clients.dashboard.activeContract.create') }}
        </v-btn>
      </slot>
    </template>
    <div class="clients-dashboard-modal-new-contract__content">
      <div>
        <ui-block
          class="required-asterisk"
          id="newContractNameLabel"
          :label="$t('clients.dashboard.activeContract.form.name.label')"
        >
          <template v-slot:body>
            <v-text-field
              v-model="modelContract.name"
              id="newContractName"
              :prepend-inner-icon="icons.mdiLabel"
              solo
              flat
              outlined
              dense
              :placeholder="$t('clients.dashboard.activeContract.form.name.placeholder')"
              :error-messages="simpleErrors('modelContract', 'name')"
            />
          </template>
        </ui-block>
        <ui-block
          class="required-asterisk"
          id="newContractdateStartLabel"
          :label="$t('clients.dashboard.activeContract.form.dateStart.label')"
        >
          <template v-slot:body>
            <v-menu transition="scale-transition" min-width="auto">
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="modelContract.dateStart"
                  id="newContractdateStart"
                  readonly
                  :prepend-inner-icon="icons.mdiSortCalendarAscending"
                  solo
                  flat
                  outlined
                  dense
                  :placeholder="$t('clients.dashboard.activeContract.form.dateEnd.placeholder')"
                  v-on="on"
                  :error-messages="simpleErrors('modelContract', 'dateStart')"
                />
              </template>
              <v-date-picker v-model="modelContract.dateStart" no-title />
            </v-menu>
          </template>
        </ui-block>
        <ui-block
          class="required-asterisk"
          id="newContractdateEndLabel"
          :label="$t('clients.dashboard.activeContract.form.dateEnd.label')"
        >
          <template v-slot:body>
            <v-menu transition="scale-transition" min-width="auto">
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="modelContract.dateEnd"
                  id="newContractdateEnd"
                  readonly
                  :prepend-inner-icon="icons.mdiSortCalendarAscending"
                  solo
                  flat
                  outlined
                  dense
                  :placeholder="$t('clients.dashboard.activeContract.form.dateEnd.placeholder')"
                  v-on="on"
                  :error-messages="simpleErrors('modelContract', 'dateEnd')"
                />
              </template>
              <v-date-picker v-model="modelContract.dateEnd" no-title />
            </v-menu>
          </template>
        </ui-block>
        <div class="tw-text-center">
          <v-btn @click="addNewContract" small class="tw-mt-2" outlined color="#4CAF50">
            <v-icon v-if="!isEdit" class="tw-mr-2">{{ icons.mdiPlus }}</v-icon>
            {{ $t(`button.${isEdit ? 'save' : 'add'}`) }}
          </v-btn>
        </div>
      </div>
    </div>
  </v-menu>
</template>

<script>
import ErrorsMixin from '@/mixins/errors.mixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mdiSortCalendarDescending, mdiSortCalendarAscending, mdiPlus, mdiLabel } from '@mdi/js'
import UiBlock from '@/components/UI/Block.vue'

const initModelContract = () => ({
  name: '',
  dateStart: '',
  dateEnd: '',
  client: '',
})

export default {
  name: 'ClientsDashboardModalNewContract',
  mixins: [validationMixin, ErrorsMixin],
  components: {
    UiBlock,
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    initialData: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      icons: {
        mdiSortCalendarDescending,
        mdiSortCalendarAscending,
        mdiPlus,
        mdiLabel,
      },
      modelContract: {
        ...initModelContract(),
      },
      openModal: false,
    }
  },
  updated() {
    if (this.isEdit === true) {
      this.modelContract = {
        ...initModelContract(),
        ...this.initialData,
      }
    }
  },
  methods: {
    addNewContract() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$emit('add', { ...this.modelContract, client: `clients/${this.client.uuid}` })
        this.$v.$reset()
        this.openModal = false
        this.modelContract = { ...initModelContract() }
      }
    },
  },
  validations() {
    return {
      modelContract: {
        name: {
          required,
        },
        dateStart: {
          required,
        },
        dateEnd: {
          required,
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.clients-dashboard-modal-new-contract {
  &__content {
    @apply tw-p-4;
    background-color: $white;
  }
}
</style>
